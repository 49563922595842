import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
      <h1>Hello World</h1>
      <h3>This is a render sample static site, pretty cool this.</h3>
    </>
  );
}

export default App;
